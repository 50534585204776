// import "./admin.css";
// import "./admin2.css";

function Monitor_User_Activity() {
  return (
      <div class="container">
        <div class="card-monitor">
          <table class="table-faculty">
            <tr class="data-table">
              <th class="heading-table">Timezone</th>
              <th>Central Daylight Timezone</th>
            </tr>
            <tr class="data-table">
              <td class="heading-table">Country</td>
              <td>USA</td>
            </tr>
            <tr class="data-table">
              <td class="heading-table">Time spent on the portal(sec)</td>
              <td>120</td>
            </tr>
            <tr class="data-table">
              <td class="heading-table">Number of Active users</td>
              <td>200</td>
            </tr>
            <tr class="data-table">
              <td class="heading-table">Number of daily logins</td>
              <td>150</td>
            </tr>
            <tr class="data-table">
              <td class="heading-table">
                Number of new Job postings in a week
              </td>
              <td>90</td>
            </tr>
            <tr class="data-table">
              <td class="heading-table">Number of Jobs applied</td>
              <td>200</td>
            </tr>
          </table>
        </div>
      </div>
  )
}
export default Monitor_User_Activity;
