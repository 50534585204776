import React from "react";

function Academia_About() {
  return (
    <>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-12 sol-md-8 col-lg-8 col-8">
            <div class="academia-about">
              <h1>Institution Name</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <h3>Research Areas</h3>
              <ol class="about-list">
                <li>Psychology</li>
                <li>Mathematics</li>
                <li>Physiology</li>
              </ol>
            </div>
          </div>
          <div class="col-md-2 col-lg-2 col-2"></div>
        </div>
      </div>
      <div class="container justify-content-center">
        <table class="w-100 table-faculty">
          <thead class="thead-faculty">
            <th>Professor</th>
            <th>Postion</th>
            <th>Email</th>
            <th>Contact</th>
          </thead>
          <tbody>
            <tr>
              <td>John Doe</td>
              <td>Phd Assistant</td>
              <td>jvd1902@mavs.uta.edu</td>
              <td>+1 684-274-5982</td>
            </tr>
            <tr>
              <td>John Doe</td>
              <td>Phd Assistant</td>
              <td>jvd1902@mavs.uta.edu</td>
              <td>+1 684-274-5982</td>
            </tr>
            <tr>
              <td>John Doe</td>
              <td>Phd Assistant</td>
              <td>jvd1902@mavs.uta.edu</td>
              <td>+1 684-274-5982</td>
            </tr>
            <tr>
              <td>John Doe</td>
              <td>Phd Assistant</td>
              <td>jvd1902@mavs.uta.edu</td>
              <td>+1 684-274-5982</td>
            </tr>
            <tr>
              <td>John Doe</td>
              <td>Phd Assistant</td>
              <td>jvd1902@mavs.uta.edu</td>
              <td>+1 684-274-5982</td>
            </tr>
          </tbody>
        </table>
      </div>
      </>
  );
}

export default Academia_About;
