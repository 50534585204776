import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// import "./admin.css";
import img_url from "./img/PieChart.png"
function Admin() {
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const username = userData?.name;
  const Role = userData?.role;

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    console.log(Role);
    if(!loggedIn){
        navigate("/");
    }
    else{
      if(Role != 1){
        console.log(typeof(Role));
        navigate("/");
      }
    }
  }, [navigate]);
  return (
    <>
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-5 col-sm-10 col-md-10 col-lg-5 p-1">
          <div class="academia-dashboard p-1">
            <div class="col-12 text-center">
              <h2>Dashboard</h2>
            </div>
            <div class="row">
              <div class="col-6 col-sm-6 col-md-12 col-lg-6">
                <div class="p-1">
                  <div class="academia-card">
                    <Link to="/Create_User_Entry" class="text-no-decoration">
                      <h3>Create User</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-12 col-lg-6">
                <div class="p-1">
                  <div class="academia-card">
                    <Link to="/View_Users" class="text-no-decoration">
                      <h3>View Registrations</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-12 col-lg-6">
                <div class="p-1">
                  <div class="academia-card">
                    <Link to="/Approve_Users" class="text-no-decoration">
                      <h3>Approve Users</h3>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div class="col-6 col-sm-6 col-md-12 col-lg-6">
                <div class="p-1">
                  <div class="academia-card">
                    <Link to="#" class="text-no-decoration">
                      <h3>View Registrations</h3>
                    </Link>
                  </div>
                </div>
              </div> */}
              <div class="col-6 col-sm-6 col-md-12 col-lg-6">
                <div class="p-1">
                  <div class="academia-card">
                    <Link to="#" class="text-no-decoration">
                      <h3>Generate Reports</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-12 col-lg-6">
                <div class="p-1">
                  <div class="academia-card">
                    <Link to="/Monitor_User_Activity" class="text-no-decoration">
                      <h3>Monitor User Activity</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-12 col-lg-6">
                <div class="p-1">
                  <div class="academia-card">
                    <Link to="/Troubleshoot" class="text-no-decoration">
                      <h3>Troubleshoot Issues</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-12 col-lg-6">
                <div class="p-1">
                  <div class="academia-card">
                    <Link to="/Chat_Test" class="text-no-decoration">
                      <h3>Chat</h3>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          
          <div class="col-5 col-sm-10 col-md-10 col-lg-5 p-1">
          <div class="academia-dashboard p-1">
            <div class="col-12 text-center">
              <h2>Statistics</h2>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="p-2">
                  <img src={img_url}  class="w-100"alt="Pie Chart" />
                  <div class="text-center">
                    <h4>
                      <b>Status of Job Applications</b>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row justify-content-center">
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 justify-content-center">
                    <div class=" m-auto p-1 w-50">
                      <div class="academia-stats">
                        <h3>100</h3>
                        <p>Number of Job Postings</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 justify-content-center">
                    <div class=" m-auto p-1 w-50">
                      <div class="academia-stats">
                        <h3>150</h3>
                        <p>Total Applications received</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 justify-content-center">
                    <div class=" m-auto p-1 w-50">
                      <div class="academia-stats">
                        <h3>200</h3>
                        <p>Number of active users</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 justify-content-center">
                    <div class=" m-auto p-1 w-50">
                      <div class="academia-stats">
                        <h3>95%</h3>
                        <p>Success Rate</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      </>
  )
}
export default Admin;
