import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
function Dei_Accounts(){
    const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const username = userData?.name;
  const Role = userData?.role;

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    if (!loggedIn && Role != "1") {
      navigate("/");
    }
  }, [navigate]);

// Retrieve Rows in JSON
      axios.get("https://mdd8423.uta.cloud/php/dei.php",{
      // axios.get("http://localhost/urm/dei.php", {
      })
      .then((response) => {
        //  console.log(typeof(response.data))
        // var j = JSON.parse(response.data);
        // console.log(response.data[0]['Name']);
        // console.log("hi");
        // console.log(response.data[1]);
        console.log("Hey");
        var j=0,r;
        var op = "",actions="";
        var t = document.getElementById("dei_users").innerHTML;
        console.log(response.data.data.length);
        for (j=0;j< response.data.data.length;j++){
        // for(r in response.data[0]){
            r = response.data.data[j];
            console.log(r.PersonID);
            op+="<tr><td>"+r.Name+"</td><td>"+r.Line1+"</td><td>"+r.Line2+"</td><td>"+r.Zipcode+"</td><td>"+r.Email+"</td><td>"+r.Password+"</td>";
            actions = "<td><a onClick={handle} href='http://localhost:80/urm/crud.php?id="+r.PersonID+"'>Edit</a></td></tr>";
            op = op+actions;
        }
        document.getElementById("dei_users").innerHTML = t+op;
        
        // if (response.data.data.status === "Success") {
        
                
        //     console.log(op);
        //     console.log("Aree")
        // //   const urole = response.data.data.role;
        // //   if(urole>=1 || urole <=4){
        // //     sessionStorage.setItem("loggedIn", true);
        // //     sessionStorage.setItem(
        // //     "userData",
        // //     JSON.stringify(response.data.data)
        // //     );
        // //     if(urole == "1"){
        // //       window.location.href = "/Admin";
        // //       }
        // //       else if(urole == "2"){
        // //         window.location.href = "/Academia_Dashboard";
        // //       }
        // //       else if(urole == "3"){
        // //       window.location.href = "/Dei_Dashboard";
        // //       }
        // //       else if(urole == "4"){
        // //       window.location.href = "/Urm_Dashboard";
        // //       }
        // //   }
        // } else {
        //   console.log("Nahi aaya")
        // }
      })
      .catch((error) => {
        console.log("hi");
        console.error(error);
      });

    return(
        <div class="container pt-6 justify-content-center">
                <table class=" w-100 table-faculty">
                <thead class="thead-faculty">
                    <th>Name</th>
                    <th>Address Line 1</th>
                    <th>Address Line 2</th>
                    <th>Zip Code</th>
                    <th>Email</th>
                    <th>Password</th>
                    <th>Actions</th>
                </thead>
                <tbody id="dei_users">

                </tbody>
            </table>
        </div> 
    )
}

export default Dei_Accounts;