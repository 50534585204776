import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import navigate from "navigate";

const userData = JSON.parse(sessionStorage.getItem("userData"));
const username = userData?.name;
const Role = userData?.role;
const deiid = userData?.deiid;


const JobView = ({ jid, name, location, type, pay, status, onUpdateStatus }) => {

  // const handleApply = () => {
  //   // Handle the apply button click here (e.g., show a modal, redirect to an apply page, etc.)
  //   console.log(`Applying for job: ${title}`);
  // };
// jobID = jid;
//   const [jobID,setJobID] = useState("");
const [error,setError] = useState();
const [success,setSuccess] = useState();
  const handleapprove = (event) => {
        event.preventDefault();
        var i = event.currentTarget.getAttribute('value')
        axios.post('https://mdd8423.uta.cloud/php/dei.php',{
        // axios.post("http://localhost/urm/dei.php",{
            action:'Approved',
            id: i,
            deiid:deiid
        })
        .then((response) =>{
            console.log(response);
            if (response.data.status == "Success") {
                alert("Job Approved");
            }
        })
    }

    const handlereject = (event) => {
        event.preventDefault();
        var i = event.currentTarget.getAttribute('value')
        axios.post('https://mdd8423.uta.cloud/php/dei.php',{
        // axios.post("http://localhost/urm/dei.php",{
            action:'Rejected',
            id: i,
            deiid:deiid
        })
        .then((response) =>{
            console.log(response);
            if (response.data.status == "Success") {
                alert("Job Rejected");
            }
        })
    }
  return (
    <>
     <div className="job-card pt-4">
            <h3>Name: {name}</h3>
            <p>Location: {location}</p>
            <p>Position: {type}</p>
            <p>Pay: {pay} $/hr</p>
              <div className="text-right">
                <ul className="academic-job-list">
                  {/* <li>
                    <a href="#"className="href job-view p-1 text-no-decoration">
                      <i className="fa fa-solid fa-eye"></i>
                      View
                    </a>
                  </li> */}
                  <li>
                          <button
                            className="href job-accept p-1 text-no-decoration"
                            value = {jid} onClick={handleapprove}>
                            Accept
                          </button>
                        </li>
                        <li>
                        <button
                            className="href job-reject p-1 text-no-decoration"
                            value = {jid} onClick={handlereject}>
                            Decline
                          </button>
                        </li>
                </ul>
                <br />
              </div>
              <hr className="text-color-1" />
            </div>


            <div className="job-card pt-4">
                    <h2>Job</h2>
                    <p>Location</p>
                    <p>Education</p>
                    <div className="text-right">
                      <ul className="academic-job-list">
                        <li>
                          <Link
                            to="/"
                            className="href job-accept p-1 text-no-decoration"
                          >
                            Accept
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/"
                            className="href job-reject p-1 text-no-decoration"
                          >
                            Decline
                          </Link>
                        </li>
                      </ul>
                      <br />
                    </div>
                    <hr className="text-color-1" />
                  </div>
      </>
  );
};

export default JobView;
