
import { Link } from "react-router-dom";
import Quicklinks from "./Quicklinks";
import { useNavigate } from "react-router-dom";
import { useEffect,useState } from "react";
import axios from "axios";
import JobView from "./JobView";

function Apply_Jobs(){
  const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const username = userData?.name;
    const Role = userData?.role;
    const candid = userData?.candidateid;
  
    useEffect(() => {
      const loggedIn = sessionStorage.getItem("loggedIn");
      if(!loggedIn){
          navigate("/");
      }
      else{
        if(Role != 4){
          console.log(typeof(Role));
          navigate("/");
        }
      }
    }, [navigate]);


    const [jobPostings, setJobPostings] = useState([]);

    // List Jobs
    axios.post('https://mdd8423.uta.cloud/php/candidate.php?action=list')
    // axios.get('http://localhost/urm/candidate.php?action=list')
    .then((response) => {
      setJobPostings(response.data.data);
    })
    
  return (
  <div className="container pt-6">
    <div className="row justify-content-center">
      <div className="col-8 col-sm-12 col-md-8 col-lg-8">
        <div className="p-1">
          <div className="academia-jobs">
            <div className="">
              <h2>Jobs Posted</h2>
            </div>
            {jobPostings.map((job)=>(
                        <JobView
                        jid = {job.JobID}
                        desc = {job.description}
                        name={job.name}
                        location={job.location}
                        type={job.type}
                        pay={job.pay}
                        />
                    ))}
          </div>
        </div>
      </div>
      <Quicklinks />
    </div>
  </div>
  )
}

export default Apply_Jobs;
