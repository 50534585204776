function Forgot_Password() {
  return (
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-4 col-sm-12 col-md-12 col-lg-4">
            <div class="card">
              <h2>Recover Account</h2>
              <form action="/Sign">
                <input
                  type="email"
                  class="ip"
                  name="email"
                  placeholder="Enter Your Email"
                />
                <br />
                <br />
                <input
                  type="button"
                  class="ip-button"
                  value="Send Reset Link"
                />
              </form>
            </div>
          </div>
        </div>

        <div class="pt-6"></div>
        <div class="pt-6"></div>
      </div>
  );
}

export default Forgot_Password;
