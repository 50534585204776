import React, { useState } from "react";
import axios from "axios";



const UserList = ({ upid, uname, uroleid, uline1, uline2, uzipcode, uemail, upassword }) => {
  const handleidedit = (event) => {
    event.preventDefault();
    var i = event.currentTarget.getAttribute('value');
    axios.post("https://mdd8423.uta.cloud/php/admin.php",{
    //   axios.post("http://localhost/urm/admin.php",{
        action:'edit',
        id: i
    })
    .then((response) =>{
        // console.log(response.data.data[0]["JobID"]);
        window.location.href = "/User_Edit/"+response.data.data[0]["PersonID"];
    })
}


const handleiddelete = (event) => {
    event.preventDefault();
    var i = event.currentTarget.getAttribute('value');
    axios.post("https://mdd8423.uta.cloud/php/admin.php",{
    //   axios.post("http://localhost/urm/admin.php",{
        action:'delete',
        id: i
    })
    .then((response) =>{
        console.log(response);
    })
}
  return (
    <><div class='job-card pt-4'>
          <h3>Name: {uname}</h3>
          <p>PersonID: {upid}</p>
          <p>RoleID: {uroleid}</p>
          <p>Line1: {uline1}</p>
          <p>Line2: {uline2}</p>
          <p>Zipcode: {uzipcode}</p>
          <p>Email: {uemail}</p>
          <p>Password: {upassword}</p>
          <div class="text-right">
              <ul class="academic-job-list">
                  <li><a onClick={handleidedit} class="href job-update p-1 text-no-decoration" value={upid} >
                      <i class="fa fa-solid fa-eye"></i>Edit</a>
                  </li>
                  <li>
                      <a onClick={handleiddelete} value={upid} class="href job-delete p-1 text-no-decoration">
                          <i class="fa fa-solid fa-trash"></i>
                          Delete
                      </a>
                  </li>
              </ul>
          </div>
      </div><hr class="text-color-1" /></>
  );
};

export default UserList;
