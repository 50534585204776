// import "./admin.css";
// import "./admin2.css";

function Troubleshoot() {
  return (
      <div class="container">
        <div class="academia-dashboard">
          <ol class="troubleshoot-list">
            <li class="troubleshoot">Check if the Site Is Down Only for You</li>
            <li class="troubleshoot">Check the Network Connection</li>
            <li class="troubleshoot">Use Another Device</li>
            <li class="troubleshoot">Look For Error Messages in the Browser</li>
            <li class="troubleshoot">Try a Different Browser</li>
            <li class="troubleshoot">Examine DNS Records</li>
            <li class="troubleshoot">Check the Error Logs</li>
          </ol>
        </div>
      </div>
  )
}
export default Troubleshoot;
