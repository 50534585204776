import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import navigate from "navigate";
import { useEffect } from "react";

const JobApplied = ({ jid, name, location, type, pay, jstatus, onUpdateStatus }) =>{
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const candid = userData?.candidateid;
  const handlewithdraw = (event) => {
    event.preventDefault();
    
    

    var i = event.currentTarget.getAttribute('value')
    axios.post('https://mdd8423.uta.cloud/php/candidate.php',{
      // axios.post("http://localhost/urm/candidate.php",{
        action:'withdraw',
        jobid: i,
        candid:candid
    })
    .then((response) =>{
        console.log(response);
            // alert(response.data.message);
        // else
        // console.log(response.data.data[0]["JobID"]);
        // window.location.href = "http://localhost:3000/Academia_Edit_Job/"+response.data.data[0]["JobID"];
    })
}
return(
    <>
    <div className="job-card pt-4">
            <h3>Name: {name}</h3>
            <p>Location: {location}</p>
            <p>Position: {type}</p>
            <p>Pay: {pay} $/hr</p>
            <p>Status: {jstatus}</p>      
                  <p>
                <Link
                  to="/Academia_About"
                  className="href"
                  target="_blank"
                >
                  Visit Website
                </Link>
              </p>
              <div className="text-right">
                <ul className="academic-job-list">
                <li>
                    <a onClick={handlewithdraw} value={jid} className="href job-reject p-1 text-no-decoration">
                    Withdraw Application
                    </a>
                </li>
                </ul>
                <br />
              </div>
              <hr className="text-color-1" />
            </div>
    </>

)
    
}

export default JobApplied;