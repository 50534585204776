import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
function Quicklinks() {
  return (
    <div class="col-3 col-sm-12 col-md-4 col-lg-4">
      <div class="p-1">
        <div class="academia-jobs">
            <h2>Quicklinks</h2>
            <ul class="">
            <li>
                <Link to="/Urm_Dashboard">Dashboard</Link>
            </li>
            <li>
                <Link to="/Manage_Profile">Manage Profile</Link>
            </li>
            <li>
                <Link to="/Apply_Jobs">Apply for jobs</Link>
            </li>
            <li>
                <Link to="/Chats">Chats</Link>
            </li>
            <li>
                <Link to="/Applied_Jobs">Applied jobs</Link>
            </li>
            <li>
                <Link to="/Preferences">Preferences</Link>
            </li>
            <li>
                <Link to="/">SignOut</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Quicklinks;
