import logo from './logo.svg';
import './css/style.css';
import Form from './example/Form';
import { Routes,Route } from 'react-router-dom';
import AppRouter from './AppRouter';
import Header from './components/Header';
import Footer from './components/Footer';
import navigate from "navigate";
function App() {
  return (
    <>
    <Header />
    <AppRouter />
    <Footer />
    </>
  );
}

export default App;
