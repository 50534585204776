import Quicklinks from "./Quicklinks";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from 'axios';
import React,{ useState } from "react";
function Academia_Create_Job() {
  const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const username = userData?.name;
    const Role = userData?.role;
    const pid = userData?.personid;
    const aid = userData?.academiaid;
    console.log(pid);
    useEffect(() => {
      const loggedIn = sessionStorage.getItem("loggedIn");
      console.log(Role);
      if(!loggedIn){
          navigate("/");
      }
      else{
        if(Role != 2){
          console.log(typeof(Role));
          navigate("/");
        }
      }
    }, [navigate]);



    // Variables
    const [jname, setJname] = useState("");
    const [jdesc, setJdesc] = useState("");
    const [jloc, setJloc] = useState("");
    const [jpay, setJpay] = useState("");
    const [jtype, setJtype] = useState("");

    // On Change

    const handlejnamechange = (event) => {
      setJname(event.target.value);
    };
    const handlejdescchange = (event) => {
      setJdesc(event.target.value);
    };
    const handlejlocchange = (event) => {
      setJloc(event.target.value);
    };
    const handlejpaychange = (event) => {
      setJpay(event.target.value);
    };
    const handlejtypechange = (event) => {
      setJtype(event.target.value);
    };


    // Send Data on Submit
    const handlejobsubmit = (event) =>{
      event.preventDefault();
    axios
    .post("https://mdd8423.uta.cloud/php/academia.php",{
      // .post("http://localhost/urm/academia.php", {
        aid:aid,
        jname:jname,
        jdesc:jdesc,
        jloc:jloc,
        jpay:jpay,
        jtype: jtype,
        action:"create"
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === "Success") {
          window.location.href = "/Academia_Jobs";
        } else {
          // setError(response.data.message);
        }
      })
      .catch((regerror) => {
        console.log("Error");
        // console.error(regerror);
      });
    };
  return (
    <>
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-6 col-sm-12 col-md-8 col-lg-8">
            <div class="p-1">
              <div class="academia-jobs">
                <h2>Create Job</h2>
                <form onSubmit={handlejobsubmit} method="post">
                  {/* <input type="text" hidden value={pid} />  */}
                  <input type="text" class="ip" value={jname} onChange={handlejnamechange} name="job_name" placeholder="Enter Job Name" />
                  <br />
                  <br />
                  <input type="text" class="ip" value={jdesc} onChange={handlejdescchange} name="job_desc" placeholder="Enter Job Description"/>
                  <br />
                  <br />
                  <input type="text" class="ip" value={jloc} onChange={handlejlocchange} name="job_loc" placeholder="Enter Job Location"/>
                  <br />
                  <br />
                  <input type="text" class="ip" value={jpay} onChange={handlejpaychange} name="job_pay" placeholder="Enter Estimated Hourly Pay"/>
                  <br />
                  <br />
                  <select id="" value={jtype} onChange={handlejtypechange} class="ip" name="job_type">
                    <option value="PhD" selected>PhD</option>
                    <option value="postdoc">postdoc</option>
                    <option value="faculty">faculty</option>
                  </select>
                  <br />
                  <br />
                  <br />
                  <input type="submit" class="ip-button" id="jcreate" name="jcreate" value="Publish" />
                </form>
              </div>
            </div>
          </div>
          <Quicklinks />
        </div>
      </div>
      </>
  );
}

export default Academia_Create_Job;
