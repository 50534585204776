import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
function Dei_Dashboard() {
  const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const username = userData?.name;
    const Role = userData?.role;
    const DeiID = userData?.deiid;
    // console.log(DeiID);
    useEffect(() => {
      const loggedIn = sessionStorage.getItem("loggedIn");
      console.log(Role);
      if(!loggedIn){
          navigate("/");
      }
      else{
        if(Role != 3){
          // console.log(typeof(Role));
          navigate("/");
        }
      }
    }, [navigate]);
  return (
      <div class="container pt-4">
        <div class="row justify-content-center">
          <div class="col-7 col-sm-12 col-md-7 col-lg-7">
            <div class="p-1">
              <div class="academia-dashboard">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <h3 class="font-italic">Dashboard</h3>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="dei-card">
                        <Link to="/Dei_Goals" class="href text-no-decoration">
                          <h3>DEI Goals</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="dei-card">
                        <Link to="/Dei_Initiatives" class="href text-no-decoration">
                          <h3>DEI Initiatives</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="dei-card">
                        <Link
                          to="/Dei_Applications"
                          class="href text-no-decoration"
                        >
                          <h3>Applications</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="dei-card">
                        <Link to="/Dei_Events" class="href text-no-decoration">
                          <h3>Events</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="dei-card">
                        <Link to="/Chat" class="href text-no-decoration">
                          <h3>Chats</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="dei-card">
                        <Link to="#" class="href text-no-decoration">
                          <h3>Settings</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-12 col-md-4 col-lg-4">
            <div class="p-1">
              <div class="academia-dashboard">
                <div class="row justify-content-center">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-12 text-center">
                    <h3 class="font-italic">Statistics</h3>
                  </div>
                  <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                    <div class="p-1">
                      <div class="dei-stats">
                        <h2>2000</h2>
                        <p>Number Of Jobs</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                    <div class="p-1">
                      <div class="dei-stats">
                        <h2>2000</h2>
                        <p>Interested Candidate</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                    <div class="p-1">
                      <div class="dei-stats">
                        <h2>Asian Eaters</h2>
                        <p>New Event</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Dei_Dashboard;
