import { Link } from "react-router-dom";
import Quicklinks from "./Quicklinks";
import { useNavigate } from "react-router-dom";
import { useEffect,useState } from "react";
import axios from 'axios';
import JobList from "./JobList";
function Academia_Jobs() {
  const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const username = userData?.name;
    const Role = userData?.role;
  
    useEffect(() => {
      const loggedIn = sessionStorage.getItem("loggedIn");
      console.log(Role);
      if(!loggedIn){
          navigate("/");
      }
      else{
        if(Role != 2){
          console.log(typeof(Role));
          navigate("/");
        }
      }
    }, [navigate]);

    
    // Edit Job
    const handleedit = (event) => {
      var jid = this.value;
      event.preventDefault();
      // axios.post("https://mdd8423.uta.cloud/php/academia.php",{
        axios.post("http://localhost/urm/academia.php",{
          action:'edit',
          id: jid
      })
      .then((response) =>{
        window.location.href = "/Academia_Edit_Job"+response.data.jobID;
      })
    }


    const [jobPostings, setJobPostings] = useState([]);

    // List Jobs
    axios.get("https://mdd8423.uta.cloud/php/academia.php?action=list")
    // axios.get('http://localhost/urm/academia.php?action=list')
    .then((response) => {
      // var j=0,r;
      // var op = "",actions="";
      // document.getElementById("jlist").innerHTML = "";
      // var t = document.getElementById("dei_users").innerHTML;
      // console.log(response);
      setJobPostings(response.data.data)
      // for (j=0;j< response.data.data.length;j++){
      // // for(r in response.data[0]){
      //     r = response.data.data[j];
      //     console.log(r.type);
      //     op = "<div class='job-card pt-4'><h3>"+r.name+"</h3><p>Location: "+r.location+"</p><p>Position: "+r.type+"</p><p>Pay: "+r.pay+" $/hr</p>";
      //     // op+="<tr><td>"+r.Name+"</td><td>"+r.Line1+"</td><td>"+r.Line2+"</td><td>"+r.Zipcode+"</td><td>"+r.Email+"</td><td>"+r.Password+"</td>";
      //     // actions = "<td><a onClick={handle}href='http://localhost/urm/academia.php?action=edit&id="+r.PersonID+"'>Edit</a></td></tr>";

      //     actions ='<div class="text-right"><ul class="academic-job-list"><li><a onclick={handleedit} class="href job-view p-1 text-no-decoration"><i class="fa fa-solid fa-eye"></i>View</a></li>';
      //     actions +='<li><a href="#" class="href job-update p-1 text-no-decoration"><i class="fa fa-solid fa-plus"></i>Update</a></li>';
      //     actions += '<li><a href="http://localhost/urm/academia.php?action=delete&id='+r.JobID+'" class="href job-delete p-1 text-no-decoration" ><i class="fa fa-solid fa-trash"></i>Delete</a></li></ul></div></div><hr class="text-color-1" />';
      //     document.getElementById("jlist").innerHTML += op+actions;
      // }
      
    })
  return (
    
    <>
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-8 col-sm-12 col-md-8 col-lg-8">
            <div class="p-1">
              <div class="academia-jobs">
                <div class="">
                  <h2>Jobs Posted</h2>
                  <Link
                    to="/Academia_Create_Job"
                    class="job-add p-1 text-no-decoration"
                  >
                    <i class="fa fa-solid fa-plus"></i>
                    Create Job
                  </Link>
                </div>
                <div id="jlist">
                {jobPostings.map((job)=>(
                        <JobList
                        jid = {job.JobID}
                        desc = {job.description}
                        name={job.name}
                        location={job.location}
                        type={job.type}
                        pay={job.pay}
                        status={job.status}
                        />
                    ))}
                </div>
              </div>
            </div>
          </div>
          <Quicklinks />
        </div>
      </div>
      </>
  );
}

export default Academia_Jobs;
