import React, { useState } from "react";
import axios from "axios";



const JobList = ({ jid, name, location, type, pay, status, onUpdateStatus }) => {
  // const handleApply = () => {
  //   // Handle the apply button click here (e.g., show a modal, redirect to an apply page, etc.)
  //   console.log(`Applying for job: ${title}`);
  // };
// jobID = jid;
  // const [jobID,setJobID] = useState("");
  const handleidedit = (event) => {
    event.preventDefault();
    var i = event.currentTarget.getAttribute('value');
    axios.post("https://mdd8423.uta.cloud/php/academia.php",{
      // axios.post("http://localhost/urm/academia.php",{
        action:'edit',
        id: i
    })
    .then((response) =>{
        // console.log(response.data.data[0]["JobID"]);
        window.location.href = "/Academia_Edit_Job/"+response.data.data[0]["JobID"];
    })
}


const handleiddelete = (event) => {
    event.preventDefault();
    var i = event.currentTarget.getAttribute('value');
    axios.post("https://mdd8423.uta.cloud/php/academia.php",{
      // axios.post("http://localhost/urm/academia.php",{
        action:'delete',
        id: i
    })
    .then((response) =>{
        console.log(response);
        // if(response.data.status==="Success"){
        //     window.location.href='/Academia_Jobs';
        // }
        // else{
        //     console.log("Did not work");
        // }
    })
}
  return (
    <><div class='job-card pt-4'>
          <h3>Name: {name}</h3>
          <p>Location: {location}</p>
          <p>Position: {type}</p>
          <p>Pay: {pay} $/hr</p>
          <h4 class="job-accept p-1 text-center">{status}</h4>
          <div class="text-right">
              <ul class="academic-job-list">
                  <li><a onClick={handleidedit} class="href job-update p-1 text-no-decoration" value={jid} >
                      <i class="fa fa-solid fa-eye"></i>Edit</a>
                  </li>
                  <li>
                      <a onClick={handleiddelete} value={jid} class="href job-delete p-1 text-no-decoration">
                          <i class="fa fa-solid fa-trash"></i>
                          Delete
                      </a>
                  </li>
              </ul>
          </div>
      </div><hr class="text-color-1" /></>
  );
};

export default JobList;
