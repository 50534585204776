import { Link } from "react-router-dom";
import React, { Fragment } from "react";

// import "./css/app.css";
function Header() {

  function handleLogout(){
    sessionStorage.clear();
    window.location.href="/Sign";
  }
  return (
      <div class="navbar">
        <div class="container">
          <ul class="nav-list ">
          <li class="company-item p-2">
                    <Link to="/" class="nav-item-links"><p class="company-title">URM website</p></Link>
                    </li>
                    <li className="nav-item p-2">
                        <Link to="/" className="nav-item-links">Home</Link>
                    </li>
                    <li class="nav-item p-2">
                        <Link to="/Contact_Us" class="nav-item-links">
                            Contact Us
                        </Link>
                    </li>
                    <li class="nav-item p-2">
                        <Link to="/Services" class="nav-item-links">
                            Services
                        </Link>
                    </li>
                    <li class="nav-item p-2">
                        <Link to="/About" class="nav-item-links">
                            About
                        </Link>
                    </li>
                    <li class="nav-item p-2">
                        <Link to="https://vxv1754.uta.cloud/blog/" target="_blank" class="nav-item-links">
                        Blog
                        </Link>
                    </li>
                    <li className="nav-item p-2">
                    <Link onClick={handleLogout} className="nav-item-links">Logout</Link>
                    </li>
          </ul>
        </div>
      </div>
  );
}
export default Header;

// if (document.getElementById("h")) {
//   ReactDOM.render(<Header />, document.getElementById("h"));
// }
