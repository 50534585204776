import { Link } from "react-router-dom";
import Quicklinks from "./Quicklinks";
import { useNavigate } from "react-router-dom";
import { useEffect,useState } from "react";
import axios from "axios";
import JobApplied from "./JobApplied";
function Applied_Jobs(){
  const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const username = userData?.name;
    const Role = userData?.role;
    const candid = userData?.candidateid;
    const [jobPostings, setJobPostings] = useState([]);
    useEffect(() => {
      const loggedIn = sessionStorage.getItem("loggedIn");
      if(!loggedIn){
          navigate("/");
      }
      else{
        if(Role != 4){
          console.log(typeof(Role));
          navigate("/");
        }
      }
    }, [navigate]);
    axios.post('https://mdd8423.uta.cloud/php/candidate.php',{
    // axios.post('http://localhost/urm/candidate.php',{
      action:"applylist",
      candid: candid
    })
    .then((response) => {
      // console.log(response);
      setJobPostings(response.data.data);
    })

    return (
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-8 col-lg-8">
              <div className="p-1">
                <div className="academia-jobs">
                  <div className="">
                    <h2>Applied Jobs</h2>
                  </div>
                  {jobPostings.map((job)=>(
                        <JobApplied
                        jid = {job.JobID}
                        desc = {job.description}
                        name={job.name}
                        location={job.location}
                        type={job.type}
                        pay={job.pay}
                        jstatus = {job.status}
                        />
                    ))}
                </div>
              </div>
            </div>
            <Quicklinks />
          </div>
        </div>
    )
  }
export default Applied_Jobs;
