import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import navigate from "navigate";

const userData = JSON.parse(sessionStorage.getItem("userData"));
const username = userData?.name;
const Role = userData?.role;
const candid = userData?.candidateid;


const JobView = ({ jid, name, location, type, pay, status, onUpdateStatus }) => {

  // const handleApply = () => {
  //   // Handle the apply button click here (e.g., show a modal, redirect to an apply page, etc.)
  //   console.log(`Applying for job: ${title}`);
  // };
// jobID = jid;
//   const [jobID,setJobID] = useState("");
const [error,setError] = useState();
const [success,setSuccess] = useState();
  const handleapply = (event) => {
    event.preventDefault();
    
    // useEffect(() => {
    //   const loggedIn = sessionStorage.getItem("loggedIn");
    //   console.log(Role);
    //   if(!loggedIn){
    //       navigate("/");
    //   }
    //   else{
    //     if(Role != 4){
    //       console.log(typeof(Role));
    //       navigate("/");
    //     }
    //   }
    // }, [navigate]);

    var i = event.currentTarget.getAttribute('value')
    axios.post('https://mdd8423.uta.cloud/php/candidate.php',{
      // axios.post("http://localhost/urm/candidate.php",{
        action:'apply',
        id: i,
        candid:candid
    })
    .then((response) => {
        console.log(response.data);
        if(response.data.status == "Success") {
            setSuccess("Applied");
        }
        else if(response.data.status == "Applied" ){
          setSuccess("Already Applied");
        }
        // else
        // console.log(response.data.data[0]["JobID"]);
        // window.location.href = "http://localhost:3000/Academia_Edit_Job/"+response.data.data[0]["JobID"];
    })
}
  return (
    <>
     <div className="job-card pt-4">
            <h3>Name: {name}</h3>
            <p>Location: {location}</p>
            <p>Position: {type}</p>
            <p>Pay: {pay} $/hr</p>
              <p>
                <Link
                  to="/Academia_About"
                  className="href"
                  target="_blank"
                >
                  Visit Website
                </Link>
              </p>
              <div className="text-right">
                <ul className="academic-job-list">
                  {/* <li>
                    <a href="#"className="href job-view p-1 text-no-decoration">
                      <i className="fa fa-solid fa-eye"></i>
                      View
                    </a>
                  </li> */}
                  <li>
                    <a onClick={handleapply} className="href job-update p-1 text-no-decoration" value={jid}>
                      <i className="fa fa-solid fa-plus"></i>Apply
                    </a>
                    <p class="text-black">{error} {success}</p>
                    
                  </li>
                </ul>
                <br />
              </div>
              <hr className="text-color-1" />
            </div>
    
    
    
    
    {/* <div class='job-card pt-4'>
          <h3>Name: {name}</h3>
          <p>Location: {location}</p>
          <p>Position: {type}</p>
          <p>Pay: {pay} $/hr</p>
          <div class="text-right" data-id={jid}>
              <ul class="academic-job-list">
                  <li><a onClick={handleidedit} class="href job-view p-1 text-no-decoration" value={jid} >
                      <i class="fa fa-solid fa-eye"></i>View</a>
                  </li>
                  <li><a href="#" class="href job-update p-1 text-no-decoration">
                      <i class="fa fa-solid fa-plus"></i>Update</a>
                  </li>
                  <li>
                      <a onClick={handleidedit} class="href job-delete p-1 text-no-decoration">
                          <i class="fa fa-solid fa-trash"></i>
                          Delete
                      </a>
                  </li>
              </ul>
          </div>
      </div><hr class="text-color-1" /> */}
      </>
  );
};

export default JobView;
