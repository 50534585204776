import { Link } from "react-router-dom";
import Quicklinks from "./Quicklinks";

function Dei_Goals() {
  return (
      <div>
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-12 col-lg-12">
              <div className="p-1">
                <div className="academia-jobs">
                  <div>
                    <h2>DEI Goals</h2>
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Goal 1: Increase Diversity</h3>
                      <p>
                        We aim to increase diversity among faculty and staff to
                        create a more inclusive and representative academic
                        community. This involves implementing inclusive hiring
                        practices and actively recruiting underrepresented
                        minority candidates.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Goal 2: Equity in Education</h3>
                      <p>
                        Our initiative focuses on ensuring equitable access to
                        education and resources for all students. We provide
                        support programs and scholarships for underprivileged
                        students to help them succeed academically and
                        professionally.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Goal 3: Inclusive Curriculum</h3>
                      <p>
                        We are committed to developing an inclusive curriculum
                        that reflects diverse perspectives and cultures. Our
                        goal is to provide students with a well-rounded
                        education that prepares them for a diverse and
                        globalized world.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>
                </div>
              </div>
            </div>
            <Quicklinks />
          </div>
        </div>
      </div>
  );
}

export default Dei_Goals;
