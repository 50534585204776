import { Link } from "react-router-dom";
import Quicklinks from "./Quicklinks";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
function Manage_Profile(){
  const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const username = userData?.name;
    const Role = userData?.role;
  
    useEffect(() => {
      const loggedIn = sessionStorage.getItem("loggedIn");
      console.log(Role);
      if(!loggedIn){
          navigate("/");
      }
      else{
        if(Role != 4){
          console.log(typeof(Role));
          navigate("/");
        }
      }
    }, [navigate]);
    return (
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-8 col-lg-8">
              <div className="p-1">
                <div className="candidate-container">
                  <h2>Register Details</h2>
                  <form action="/action_page.php">
                    <label for="fname">First name:</label>
                    <input type="text" id="fname" name="fname" className="ip" required />
                    <br />
                    <br />
                    <label for="lname">Last name:</label>
                    <input type="text" id="lname" name="lname" className="ip"required />
                    <br />
                    <br />
                    <label for="mail">E-Mail:</label>
                    <input type="mail" id="mail" name="mail" className="ip"required />
                    <br />
                    <br />
                    <label for="phoneno">Mobile No:</label>
                    <input
                      type="number"
                      id="phoneno"
                      name="phoneno"
                      className="ip"
                      required
                    />
                    <br />
                    <br />
                    <label for="education">Education:</label>
                    <input
                      type="text"
                      id="education"
                      name="education"
                      className="ip"
                      required
                    />
                    <br />
                    <br />
                    <label for="studyarea">StudyArea:</label>
                    <input
                      type="text"
                      id="studyarea"
                      name="studyarea"
                      className="ip"
                      required
                    />
                    <br />
                    <br />
                    <label for="resume">Resume</label>
                    <input type="file" name="resume" id="" className="ip"required />
                    <br />
                    <br />
                    <label for="academic_records">Academic Records</label>
                    <input
                      type="file"
                      name="academic_records"
                      id=""
                      className="ip"
                      required
                    />
                    <br />
                    <br />
                    <label for="cv">Cover Letter</label>
                    <input type="file" name="cv" id="" class="ip" required />
                    <br />
                    <br />
                  </form>
                  <br />
                  <br />
                  <input type="submit" value="Submit" class="ip-button"  />
                </div>
              </div>
            </div>
            <Quicklinks />
          </div>
        </div>
       
    )
  }
export default Manage_Profile;
