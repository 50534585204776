import { Link } from "react-router-dom";

function Quicklinks() {
  return (
    <div class="col-3 col-sm-12 col-md-4 col-lg-4">
      <div class="p-1">
        <div class="academia-jobs">
            <h2>Quicklinks</h2>
            <ul class="">
            <li>
                <Link to="/Dei_Dashboard" className="href">
                Dashboard
                </Link>
            </li>
            <li>
                <Link to="/Dei_Events" className="href">
                Event List
                </Link>
            </li>
            <li>
                <Link to="/Dei_Create_events" className="href">
                Create Event
                </Link>
            </li>
            <li>
                <Link to="/Dei_Applications" className="href">
                View Applications
                </Link>
            </li>
            <li>
                <Link to="/academia_chats" className="href">
                Chats
                </Link>
            </li>
            <li>
                <Link to="/academia_chats" className="href">
                Settings
                </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Quicklinks;
