import axios from 'axios';
import React,{ useState } from "react";

function Form(){
    const [x,setx] = useState("");
    const [error, setError] = useState("");
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const handlexchange = (event) => {
        setx(event.target.value);
      };

      const handleSubmit = (event) => {
        event.preventDefault();
        axios
          .post("http://localhost/urm/form.php", {
            x: x,
          })
          .then((response) => {
                console.log("hi")
                console.log(response)
            // console.log(response.data.status);
            // console.log("hi");
            // if (response.data.status === "Success") {
            //     console.log(response.data.data);
            // //   sessionStorage.setItem("loggedIn", true);
            //   sessionStorage.setItem(
            //     "userData",
            //     JSON.stringify(response.data.data)
            //   );
    
            //   window.location.href = "/";
            // } else {
            //   setError(response.data.message);
            // }
          })
          .catch((error) => {
            console.log("hi");
            console.error(error);
          });
      };
    return(
            <><form onSubmit={handleSubmit} method="GET">
            <input type="text" name="example" onChange={handlexchange} value={x} id="example" />
            <input type="submit" value="Connect" />
        </form></>
            
    )
}
export default Form;