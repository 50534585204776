import { Link } from "react-router-dom";
import Quicklinks from "./Quicklinks";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
function Preferences(){
  const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const username = userData?.name;
    const Role = userData?.role;
  
    useEffect(() => {
      const loggedIn = sessionStorage.getItem("loggedIn");
      console.log(Role);
      if(!loggedIn){
          navigate("/");
      }
      else{
        if(Role != 4){
          console.log(typeof(Role));
          navigate("/");
        }
      }
    }, [navigate]);
  return (
    
      <div className="container pt-6">
        <div className="row justify-content-center">
          <div className="col-8 col-sm-12 col-md-8 col-lg-8">
            <div className="p-1">
              <div className="candidate-container">
                <h2>Register Details</h2>
                <form action="candidate_dashboard.html">
                  <label for="preference1">Position:</label>
                  <input
                    type="text"
                    id="preference1"
                    name="preference1"
                    className="ip"
                  />
                  <br />
                  <br />
                  <label for="preference2">Location:</label>
                  <input
                    type="text"
                    id="preference"
                    name="preference"
                    className="ip"
                  />
                  <br />
                  <br />
                </form>
                <br />
                <br />
                <input type="submit" value="Submit" />
                <input type="button" value="Back" onclick="history.back()" />
              </div>
            </div>
          </div>
          <Quicklinks />
        </div>
      </div>
  )
}

export default Preferences;
