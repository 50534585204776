import React, { useState } from 'react';

function Contact_Us() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    description: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name.trim() === '') {
      alert('Please enter your name.');
      return;
    }
    if (!isValidEmail(formData.email)) {
      alert('Please enter a valid email address.');
      return;
    }
  };

  const isValidEmail = (email) => {
    // Basic email validation pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <>
      <div className="container pt-4 text-center">
        <h1 className="text-color-1">Connect with Us!</h1>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-4 col-sm-12 col-md-12 col-lg-4">
            <div className="card">
              <h2>Provide Information Here</h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  className="ip"
                  name="name"
                  placeholder="Enter Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <br />
                <br />
                <input
                  type="email"
                  className="ip"
                  name="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <br />
                <br />
                <input
                  type="text"
                  className="ip"
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
                <br />
                <br />
                <textarea
                  name="description"
                  className="ip"
                  placeholder="Enter Description"
                  cols="10"
                  rows="5"
                  value={formData.description}
                  onChange={handleChange}
                  required
                ></textarea>
                <br />
                <br />
                <input
                  type="submit"
                  className="ip-button"
                  value="Connect"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact_Us;