import React from "react";
import { Route, Routes } from "react-router-dom";

import About from "./pages/About"
import Services from "./pages/Services"
import Contact_Us from "./pages/Contact_Us";
import Forgot_Password from "./pages/Forgot_Password";
import Sign from "./pages/Sign";
import Home from "./pages/Home";

// ACADEMIA IMPORTS
import Academia_Dashboard from "./pages/Academia/Academia_Dashboard";
import Academia_About from "./pages/Academia/Academia_About";
import Academia_Application from "./pages/Academia/Academia_Application";
import Academia_Jobs from "./pages/Academia/Academia_Jobs";
import Academia_Create_Job from "./pages/Academia/Academia_Create_Job";
import Academia_Candidate_Profile from "./pages/Academia/Academia_Candidate_Profile";
import Academia_Candidates from "./pages/Academia/Academia_Candidates";




// DEI IMPORTS 
import Dei_Dashboard from "./pages/Dei/Dei_Dashboard";
import Dei_Applications from "./pages/Dei/Dei_Applications";
import Dei_Create_Events from "./pages/Dei/Dei_Create_Events";
import Dei_Events from "./pages/Dei/Dei_Events";
import Dei_Goals from "./pages/Dei/Dei_Goals";
import Dei_Initiatives from "./pages/Dei/Dei_Initiatives";

// Candidate Imports
import Urm_Dashboard from "./pages/Candidate/Urm_Dashboard";
import Applied_jobs from "./pages/Candidate/Applied_Jobs";
import Apply_Jobs from "./pages/Candidate/Apply_Jobs";
import Preferences from "./pages/Candidate/Preferences";
import Manage_Profile from "./pages/Candidate/Manage_Profile";

// Admin Imports
import Admin from "./pages/Admin/Admin";
import Approve_Users from "./pages/Admin/Approve_Users";
import Create_User_Entry from "./pages/Admin/Create_User_Entry";
import Delete_User_Entry from "./pages/Admin/Delete_User_Entry";
import Monitor_User_Activity from "./pages/Admin/Monitor_User_Activity";
import Troubleshoot from "./pages/Admin/Troubleshoot";
import Update_User_Entry from "./pages/Admin/Update_User_Entry";
import View_Registrations from "./pages/Admin/View_Registrations";
import Update from "./pages/Admin/Update";
import Dei_Accounts from "./pages/Admin/Dei_Accounts";
import Academia_Edit_Job from "./pages/Academia/Academia_Edit_Job";
import View_Users from "./pages/Admin/View_Users";
import Chats from "./pages/Chats.js";
function AppRouter(){
    return(
        <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/About" element={<About />}/>
            <Route path="/Services" element={<Services/>}/>
            <Route path="/Contact_Us" element={<Contact_Us/>}/>
            <Route path="/Forgot_Password" element={<Forgot_Password />} />
            <Route path="/Chats" element={<Chats />} />
            {/* Academia Dashboard Pages */}
            <Route path="/Academia_Edit_Job/:id" element={<Academia_Edit_Job />} />
            <Route path="/Academia_Dashboard" element={<Academia_Dashboard />}/>
            <Route path="/Academia_About" element={<Academia_About/>}/>
            <Route path="/Academia_Application" element={<Academia_Application/>}/>
            <Route path="/Academia_Jobs" element={<Academia_Jobs/>}/>
            <Route path="/Academia_Create_Job" element={<Academia_Create_Job/>}/>
            <Route path="/Academia_Candidates" element={<Academia_Candidates/>}/>
            <Route path="/Academia_Candidate_Profile" element={<Academia_Candidate_Profile/>}/>
            <Route path="/Sign" element={<Sign />}/>
            <Route path="/Forgot_Password" element={<Forgot_Password />}/>

            {/* Dei Dashboard  */}
            <Route path="/Dei_Dashboard" element={<Dei_Dashboard />} />
            <Route path="/Dei_Goals" element={<Dei_Goals />} />
            <Route path="/Dei_Initiatives" element={<Dei_Initiatives />} />
            <Route path="/Dei_Events" element={<Dei_Events />} />
            <Route path="/Dei_Create_Events" element={<Dei_Create_Events />} />
            <Route path="/Dei_Applications" element={<Dei_Applications />} />

            {/* Candidate Dashboard */}
            <Route path="/Urm_Dashboard" element={<Urm_Dashboard/>} />
            <Route path="/Applied_Jobs" element={<Applied_jobs />} />
            <Route path="/Apply_Jobs" element={<Apply_Jobs />} />
            <Route path="/Manage_Profile" element={<Manage_Profile />} />
            <Route path="/Preferences" element={<Preferences />} />

            {/* Admin Dashboard */}
            <Route path="/Admin" element={<Admin/>} />
            <Route path="/Approve_Users" element={<Approve_Users />} />
            <Route path="/Create_User_Entry" element={<Create_User_Entry />} />
            <Route path="/Delete_User_Entry" element={<Delete_User_Entry/>} />
            <Route path="/Monitor_User_Activity" element={<Monitor_User_Activity />} />
            <Route path="/Troubleshoot" element={<Troubleshoot/>} />
            <Route path="/Update_User_Entry" element={<Update_User_Entry/>}/>
            <Route path="/View_Registrations" element={<View_Registrations/>} />
            <Route path="/Update" element={<Update />} />
            <Route path="/Dei_Accounts" element={<Dei_Accounts/>} />
            <Route path="/View_Users" element={<View_Users/>} />
        </Routes>
    )
}
export default AppRouter;