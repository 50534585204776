import React from "react";

import Quicklinks from "./Quicklinks";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
function Academia_Application() {
  const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const username = userData?.name;
    const Role = userData?.role;
  
    useEffect(() => {
      const loggedIn = sessionStorage.getItem("loggedIn");
      console.log(Role);
      if(!loggedIn){
          navigate("/");
      }
      else{
        if(Role != 2){
          console.log(typeof(Role));
          navigate("/");
        }
      }
    }, [navigate]);
  return (
    <>
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-8 col-sm-12 col-md-8 col-lg-8">
            <div class="p-1">
              <div class="academia-jobs">
                <div class="">
                  <h2>Received Applications</h2>
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Quicklinks />
        </div>
      </div>
      </>
  );
}

export default Academia_Application;
