import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import React,{ useState } from "react";
function Sign() {
  // Login
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [pwd, setPwd] = useState("");
  const [error, setError] = useState("");

  // Register
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [regerror,setRegError] = useState("");
  const [regrole,setRegrole] = useState("");

  const handlenamechange = (event) => {
    setName(event.target.value);
  };
  const handlepwdchange = (event) => {
    setPwd(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
    .post("https://mdd8423.uta.cloud/php/login.php",{
      // .post("http://localhost/urm/login.php", {
        name: name,
        password: pwd,
      })
      .then((response) => {
        console.log(response);
        console.log("hi vatsal");
        if (response.data.status === "Success") {
          const urole = response.data.data.role;
          if(urole>=1 || urole <=4){
            sessionStorage.setItem("loggedIn", true);
            sessionStorage.setItem(
            "userData",
            JSON.stringify(response.data.data)
            );
            if(urole == "1"){
              navigate("/Admin");
              }
              else if(urole == "2"){
                navigate("/Academia_Dashboard");
              }
              else if(urole == "3"){
                navigate("/Dei_Dashboard");
              }
              else if(urole == "4"){
                navigate('/Urm_Dashboard');
              }
          }
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        console.log("hi");
        console.error(error);
      });
    };


    

    // Register Functionality

    const handlefnamechange = (event) => {
      setFname(event.target.value);
    };
    const handlelnamechange = (event) => {
      setLname(event.target.value);
    };
    const handleemailchange = (event) => {
      setEmail(event.target.value);
    };
    const handlepasswordchange = (event) => {
      setPassword(event.target.value);
    };

    const handleRegrolechange = (event) => {
      setRegrole(event.target.value);
    }
    const handleRegisterSubmit = (event) =>{
      event.preventDefault();
    axios
    .post("https://mdd8423.uta.cloud/php/register.php",{
      // .post("http://localhost/urm/register.php", {
        fname: fname,
        email:email,
        password:password,
        role: regrole
      })
      .then((response) => {
        console.log(response);
        console.log("hi");
        if (response.data.status === "Success") {
          navigate("/Sign");
        } else {
          setError(response.data.message);
        }
      })
      .catch((regerror) => {
        console.log("hi");
        console.error(regerror);
      });
    };

  return (
    <>
      <div class="container pt-4 text-center">
        <h1 class="text-color-1 font-italic">Join Our Community</h1>
      </div>
      <div class="container pt-2 pb-4">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-4">
            <div class="p-1">
              <div class="card">
                <h3 class="head-color">Login</h3>
                <form onSubmit={handleSubmit} class="p-0" method="POST">
                  <input type="email" name="email" value={name} onChange={handlenamechange} class="ip" placeholder="Email Address" required/>
                  <br />
                  <br />
                  <input type="password" name="password" value={pwd} onChange={handlepwdchange} class="ip" placeholder="Password" required/>
                  <br />
                  <br />
                  <Link to="/Forgot_Password">Forgot Password?</Link>
                  {/* <a href="forgot_password.html" class="href">Forgot Password?</a> */}
                  <br />
                  <br />
                  <br />
                  <input type="submit" class="ip-button" value="Sign In"/>
                  {error}
                </form>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-4">
            <div class="p-1">
              <div class="card">
                <h3 class="head-color">Register</h3>
                <form onSubmit={handleRegisterSubmit} method="POST">
                  <input type="text" name="fname" value={fname} onChange={handlefnamechange} class="ip" placeholder="Full Name" required/>
                  <br />
                  <br />
                  <select type="text" value={regrole} onChange={handleRegrolechange} class="ip">
                    <option>Academia</option>
                    <option>Candidate</option>
                    <option>DEI</option>
                  </select>
                  
                  <input type="email" name="email" value={email} onChange={handleemailchange} class="ip" placeholder="Email Address" required/>
                  <br />
                  <br />
                  <input type="password" name="password" value={password} onChange={handlepasswordchange} class="ip" placeholder="Password" required/>
                  <br />
                  <br />
                  <br />
                  <button type="submit" class="ip-button">
                    Register
                  </button>
                  {regerror}
                </form>
              </div>
            </div>
          </div>
          <div class="col-2"></div>
        </div>
      </div></>
  );
}

export default Sign;
