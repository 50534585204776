// import Quicklinks from "./Quicklinks";
// import { useNavigate, useParams } from "react-router-dom";
// import { useEffect } from "react";
// import axios from 'axios';
// import React,{ useState } from "react";
// function Academia_Edit_Job() {
//     const navigate = useNavigate();
//     const userData = JSON.parse(sessionStorage.getItem("userData"));
//     const username = userData?.name;
//     const Role = userData?.role;
//     const { id } = useParams();
    
//     useEffect(() => {
//       const loggedIn = sessionStorage.getItem("loggedIn");
//       if (!loggedIn && Role != "2") {
//         navigate("/");
//       }
//     }, [navigate]);

// const [details,setDetails] = useState({
//     name:"",
//     description:"",
//     location:"",
//     pay:"",
//     type:"",
// });
// const[changed,setChanged]=useState(false);
// const [tempdetails,setTempdetails] = useState("");
// axios.post("http://localhost/urm/academia.php",{
//     action:"editjob",
//     id:id
// }).then((response)=>{
//     setDetails(response.data.data[0]);
//     setTempdetails(response.data.data[0]);
// })

    
//     // // Variables
//     // var [jname, setJname] = useState("");
//     // var [jdesc, setJdesc] = useState("");
//     // var [jloc, setJloc] = useState("");
//     // var [jpay, setJpay] = useState("");
//     // var [jtype, setJtype] = useState("");

//     // // On Change
//     // jname = details.name;
//     // jdesc = details.description;
//     // jloc = details.location;
//     // jpay = details.pay;
//     // jtype = details.type;

//     // const handlejnamechange = (event) => {
//     //   setJname(event.target.value);
//     // };
//     // const handlejdescchange = (event) => {
//     //   setJdesc(event.target.value);
//     // };
//     // const handlejlocchange = (event) => {
//     //   setJloc(event.target.value);
//     // };
//     // const handlejpaychange = (event) => {
//     //   setJpay(event.target.value);
//     // };
//     // const handlejtypechange = (event) => {
//     //   setJtype(event.target.value);
//     // };

//     const handleChange = (e) =>{
//         const {name,value} = e.target;
//         setDetails((prevDetails) => ({
//             ...prevDetails,
//             [name]:value.value,
//         }));
//     }

//     // Send Data on Submit
//     const handlejobsubmit = (event) =>{
//       event.preventDefault();
//     axios
//     // .post("https://mdd8423.uta.cloud/php/academia.php",{
//       .post("http://localhost/urm/academia.php", {
//         jname:details.name,
//         jdesc:details.description,
//         jloc:details.location,
//         jpay:details.pay,
//         jtype: details.type,
//         action:"create"
//       })
//       .then((response) => {
//         console.log(response);
//         if (response.data.status == "Success") {
//           window.location.href = "/Academia_Jobs";
//         } else {
//           // setError(response.data.message);
//         }
//       })
//       .catch((regerror) => {
//         console.log("Error");
//         // console.error(regerror);
//       });
//     };
//   return (
//     <>
//       <div class="container pt-6">
//         <div class="row justify-content-center">
//           <div class="col-6 col-sm-12 col-md-8 col-lg-8">
//             <div class="p-1">
//               <div class="academia-jobs">
//                 <h2>Create Job</h2>
//                 <form onSubmit={handlejobsubmit} method="post">
//                   <input type="text" class="ip" value={tempdetails.name} 
//                   onChange= {(e) => {
//                     setChanged(true);
//                     setTempdetails({
//                         ...tempdetails,
//                         name:e.target.value,
//                     })
//                   }}
//                    name="name" placeholder="Enter Job Name" />
//                   <br />
//                   <br />
//                   <input type="text" class="ip" value={tempdetails.description}  
//                   onChange={e => setDetails({ ...setDetails,description: e.target.value })}
//                   name="job_desc" placeholder="Enter Job Description"/>
//                   <br />
//                   <br />
//                   <input type="text" class="ip" value={details.location} name="job_loc" placeholder="Enter Job Location"/>
//                   <br />
//                   <br />
//                   <input type="text" class="ip" value={details.pay} name="job_pay" placeholder="Enter Estimated Hourly Pay"/>
//                   <br />
//                   <br />
//                   <select id=""  class="ip" value={details.type} name="job_type">
//                     <option value="PhD">PhD</option>
//                     <option value="postdoc">postdoc</option>
//                     <option value="faculty">faculty</option>
//                   </select>
//                   <br />
//                   <br />
//                   <br />
//                   <input type="submit" class="ip-button" id="jcreate" name="jcreate" value="Publish" />
//                 </form>
//               </div>
//             </div>
//           </div>
//           <Quicklinks />
//         </div>
//       </div>
//       </>
//   );
// }

// export default Academia_Edit_Job;



// Chat GPT

import Quicklinks from "./Quicklinks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import React from "react";

function Academia_Edit_Job() {
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const username = userData?.name;
  const Role = userData?.role;
  const { id } = useParams();

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    console.log(Role);
    if(!loggedIn){
        navigate("/");
    }
    else{
      if(Role != 2){
        console.log(typeof(Role));
        navigate("/");
      }
    }
  }, [navigate]);

  const [details, setDetails] = useState({
    name: "",
    description: "",
    location: "",
    pay: "",
    type: "",
  });

  const [changed, setChanged] = useState(false);

  const [tempdetails, setTempdetails] = useState({
    name: "",
    description: "",
    location: "",
    pay: "",
    type: "",
  });

  useEffect(() => {
    axios.post("https://mdd8423.uta.cloud/php/academia.php", { action: "editjob", id: id })
    // axios.post("http://localhost/urm/academia.php", { action: "editjob", id: id })
      .then((response) => {
        setDetails(response.data.data[0]);
        setTempdetails(response.data.data[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setChanged(true);
  };

  const handlejobsubmit = (event) => {
    event.preventDefault();
    axios.post('https://mdd8423.uta.cloud/php/academia.php',{
    // axios.post("http://localhost/urm/academia.php", {
      action: "update",
      id: id,
      name: details.name,
      description: details.description,
      location: details.location,
      pay: details.pay,
      type: details.type,
    })
    .then((response) => {
      // console.log(response);
      if (response.data.status === "Success") {
        alert("Job Updated Successfully");
        // window.location.href = "/Academia_Jobs";
      } else {
        alert(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  };

  return (
    <>
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-6 col-sm-12 col-md-8 col-lg-8">
            <div class="p-1">
              <div class="academia-jobs">
                <h2>Edit Job</h2>
                <form onSubmit={handlejobsubmit} method="post">
                  <input
                    type="text"
                    class="ip"
                    value={details.name}
                    onChange={handleChange}
                    name="name"
                    placeholder="Enter Job Name"
                  />
                  <br /> <br />
                  <input
                    type="text"
                    class="ip"
                    value={details.description}
                    onChange={handleChange}
                    name="description"
                    placeholder="Enter Job Description"
                  />
                  <br /> <br />
                  <input
                    type="text"
                    class="ip"
                    value={details.location}
                    onChange={handleChange}
                    name="location"
                    placeholder="Enter Job Location"
                  />
                  <br /> <br />
                  <input
                    type="text"
                    class="ip"
                    value={details.pay}
                    onChange={handleChange}
                    name="pay"
                    placeholder="Enter Estimated Hourly Pay"
                  />
                  <br /> <br />
                  <select
                    id=""
                    class="ip"
                    value={details.type}
                    onChange={handleChange}
                    name="type"
                  >
                    <option value="PhD">PhD</option>
                    <option value="postdoc">postdoc</option>
                    <option value="faculty">faculty</option>
                  </select>
                  <br /> <br /> <br />
                  <input
                    type="submit"
                    class="ip-button"
                    id="jcreate"
                    name="jcreate"
                    value="Update"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Quicklinks />
    </>
  );
}

export default Academia_Edit_Job;