import Quicklinks from "./Quicklinks";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Academia_Candidates() {
  const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const username = userData?.name;
    const Role = userData?.role;
  
    useEffect(() => {
      const loggedIn = sessionStorage.getItem("loggedIn");
      console.log(Role);
      if(!loggedIn){
          navigate("/");
      }
      else{
        if(Role != 2){
          console.log(typeof(Role));
          navigate("/");
        }
      }
    }, [navigate]);
  return (
    <>
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-8 col-sm-12 col-md-12 col-lg-12">
            <div class="p-1">
              <div class="academia-jobs">
                <div>
                  <h2>Candidates</h2>
                  <input
                    type="text"
                    placeholder="Search"
                    class="display-inline ip"
                    name="searchbar"
                  />
                  <p class="text-right">
                    <select id="" class="ip" name="search_filters">
                      <option value="name">Name</option>
                      <option value="location">Location</option>
                      <option value="education">Education</option>
                      <option value="research">Research</option>
                    </select>
                  </p>
                  <button class="ip-button">Search</button>
                </div>

                <div class="job-card pt-4">
                  <h2>Candidate Name</h2>
                  <p>Location</p>
                  <p>Education</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-view p-1 text-no-decoration"
                        >
                          <i class="fa fa-regular fa-eye"></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-delete p-1 text-no-decoration"
                        >
                          <i class="fa fa-regular fa-bookmark"></i>
                          Bookmark
                        </a>
                      </li>
                      <li>
                        <a
                          href="/admin/Chat.html"
                          class="href job-update p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-message"></i>
                          Chat
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h2>Candidate Name</h2>
                  <p>Location</p>
                  <p>Education</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-view p-1 text-no-decoration"
                        >
                          <i class="fa fa-regular fa-eye"></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-delete p-1 text-no-decoration"
                        >
                          <i class="fa fa-regular fa-bookmark"></i>
                          Bookmark
                        </a>
                      </li>
                      <li>
                        <a
                          href="admin/Chat.html"
                          class="href job-update p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-message"></i>
                          Chat
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h2>Candidate Name</h2>
                  <p>Location</p>
                  <p>Education</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-view p-1 text-no-decoration"
                        >
                          <i class="fa fa-regular fa-eye"></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-delete p-1 text-no-decoration"
                        >
                          <i class="fa fa-regular fa-bookmark"></i>
                          Bookmark
                        </a>
                      </li>
                      <li>
                        <a
                          href="admin/Chat.html"
                          class="href job-update p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-message"></i>
                          Chat
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h2>Candidate Name</h2>
                  <p>Location</p>
                  <p>Education</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-view p-1 text-no-decoration"
                        >
                          <i class="fa fa-regular fa-eye"></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-delete p-1 text-no-decoration"
                        >
                          <i class="fa fa-regular fa-bookmark"></i>
                          Bookmark
                        </a>
                      </li>
                      <li>
                        <a
                          href="admin/Chat.html"
                          class="href job-update p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-message"></i>
                          Chat
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
              </div>
            </div>
          </div>
          <Quicklinks />
        </div>
      </div></>
  );
}

export default Academia_Candidates;
