
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Urm_Dashboard(){
    const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const username = userData?.name;
    const Role = userData?.role;
  
    useEffect(() => {
      const loggedIn = sessionStorage.getItem("loggedIn");
      console.log(Role);
      if(!loggedIn){
          navigate("/");
      }
      else{
        if(Role != 4){
          console.log(typeof(Role));
          navigate("/");
        }
      }
    }, [navigate]);
  return (
    <div className="container pt-4">
      <div className="row justify-content-center">
        <div className="col-7 col-sm-12 col-md-12 col-lg-12">
          <div className="p-1">
            <div className="academia-dashboard">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <h3 className="font-italic">Dashboard</h3>
                </div>
                <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                  <div className="p-2">
                    <div class="academia-card">
                      <Link to="/Apply_Jobs" class="text-no-decoration">
                        <i
                          class="fa fa-size fa-solid fa-briefcase"
                          aria-hidden="true"
                        ></i>
                        <h3>Apply for Jobs</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                  <div className="p-2">
                    <div class="academia-card">
                      <Link to="/Manage_Profile" class="text-no-decoration">
                        <i
                          class="fa fa-size fa-solid fa-briefcase"
                          aria-hidden="true"
                        ></i>
                        <h3>Manage Profile</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                  <div className="p-2">
                    <div class="academia-card">
                      <Link to="/Chats" class="text-no-decoration">
                        <i
                          class="fa fa-size fa-solid fa-briefcase"
                          aria-hidden="true"
                        ></i>
                        <h3>Chats</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                  <div className="p-2">
                    <div class="academia-card">
                      <Link to="/Applied_Jobs" class="text-no-decoration">
                        <i
                          class="fa fa-size fa-solid fa-briefcase"
                          aria-hidden="true"
                        ></i>
                        <h3>Applied Jobs</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                  <div className="p-2">
                    <div class="academia-card">
                      <Link to="/Preferences" class="text-no-decoration">
                        <i
                          class="fa fa-size fa-solid fa-briefcase"
                          aria-hidden="true"
                        ></i>
                        <h3>Preferences</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                  <div className="p-2">
                    <div class="academia-card">
                      <Link to="/" class="text-no-decoration">
                        <i
                          class="fa fa-size fa-solid fa-briefcase"
                          aria-hidden="true"
                        ></i>
                        <h3>Sign Out</h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)
}
export default Urm_Dashboard;