function Services() {
  return (
    <>
      <div class="container pt-6 text-center">
        <h1 class="text-color-1">Our Services</h1>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-3 col-sm-12 col-md-6 col-lg-3">
            <div class="p-1">
              <div class="card">
                <h2 class="text-center">Simple Setting</h2>
                <p>
                Navigate through our platform with ease, regardless of your technical expertise.Streamlined processes for quick and efficient interactions.
                Access our services on any device, ensuring a consistent experience.
                </p>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-12 col-md-6 col-lg-3">
            <div class="p-1">
              <div class="card">
                <h2 class="text-center">Online Service</h2>
                <p>
                Embrace the power of the digital world with our online service. We understand the convenience of conducting business online, and that's why we've made sure our services are available at your fingertips.
                </p>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-12 col-md-6 col-lg-3">
            <div class="p-1">
              <div class="card">
                <h2 class="text-center">Easy Hiring</h2>
                <p>
                With our easy hiring service, we simplify the recruitment process for employers, saving you valuable time and resources. Post job listings, view applicant profiles, and make informed hiring decisions with confidence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  );
}

export default Services;
