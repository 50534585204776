import React from"react";
class Chats extends React.Component{
    render(){
        return( 
            <> 
            <div class="pt-6"></div>
           <div class="container">
            <div class="row justify-content-center">
                <div class="col-8 p-2 academia-dashboard">
                    <div class="text-center">
                        <h2>Chat</h2>
                    </div>
                    <div class="text-left">
                        <b>Academia:  </b>Hello
                    </div>
                    <hr/>
                    <div class="text-left">
                        <b>Candidate:  </b>Hello
                    </div>
                    <hr/>
                    <div class="text-left">
                        <b>Academia:  </b>How Can I help you?
                    </div>
                    <hr/>
                    <div class="text-left">
                        <b>Candidate:  </b>I have Applied to your Company
                    </div>
                    <hr/>
                    <div class="text-left">
                        <b>Academia:  </b>Thank You
                    </div>
                    <hr/>
                    <div class="text-left">
                        <b>Candidate:  </b>Thank You
                    </div>
                    <hr/>
                    <div>
                        <textarea name="" class="ip" id="" cols="30" rows="3"></textarea>
                    </div>
                    <div><input type="button" class="ip-button" value="Send Message" /></div>
                </div>
            </div>
           </div>
           </>
             
        )
    }
}
export default Chats;
