import { Link } from "react-router-dom";
import React from "react";

// import "../css/style.css";

function Footer() {
  return (
    <footer class="pt-6">
      <div class="ft-container text-center">
        <div class="row">
          <div class="col-4 col-lg-4 col-md-4 col-sm-12">
            <ul class="nav-list">
              <li>
                <h2>Website Links</h2>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="pt-1">
                <Link to="/About">About</Link>
              </li>
              <li class="pt-1">
                <Link to="/Services">Services</Link>
              </li>
              <li class="pt-1">
                <Link to="/Contact_Us">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div class="col-4 col-lg-4 col-md-4 col-sm-12">
            <ul class="nav-list">
              <li>
                <h2>Other Profiles</h2>
              </li>
              <li>
                <Link to="/Urm_Dashboard">Candidate</Link>
              </li>
              <li class="pt-1">
                <Link to="/Dei_Dashboard">Dei</Link>
              </li>
              <li class="pt-1">
                <Link to="/Academia_Dashboard">Academia</Link>
              </li>
              <li class="pt-1">
                <Link to="/Admin">Admin</Link>
              </li>
              {/* <li class="pt-1"><a href="Admin/Admin.html" class="href">Admin</a></li>  ADD ADMIN DASHBOARD LINK*/}
            </ul>
          </div>
          <div class="col-4 col-lg-4 col-md-4 col-sm-12">
            <ul class="nav-list">
              <li>
                <h2>Social</h2>
              </li>
              <li>Facebook</li>
              <li class="pt-1">Instagram</li>
              <li class="pt-1">Twitter</li>
              <li class="pt-1">Threads</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
