import { Link } from "react-router-dom";
function Approve_Users() {
  return (
    <>
      <div class="container pt-6 m-auto">
        <div class="col-4 m-auto">
          <form action="#" method="post">
            <label for="name"><h2>Search Users:</h2></label>
            <input type="text" name="name" placeholder="Enter Value" class="ip"/><br/><br/>
            <input type="submit" value="Search" class="ip-button"/>
          </form>
        </div>
        <div class="pt-6">
        </div>
        <table class=" m-auto table-admin pt-6">
          <thead class="thead-faculty">
              <th>Name</th>
              <th>Email</th>
              <th>Contact</th>
              <th>Date of Birth(YYYY-MM-DD)</th>
              <th>Location</th>
              <th>Experience(years)</th>
              <th>Approve</th>
              <th>Reject</th>
          </thead>
          <tr>
            <td>John Jacob</td>
            <td>John.Jacob@gmail.com</td>
            <td>9433723140</td>
            <td>1991-05-03</td>
            <td>Plano, Texas</td>
            <td>9</td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>John Jacob</td>
            <td>John.Jacob@gmail.com</td>
            <td>9433723140</td>
            <td>1991-05-03</td>
            <td>Plano, Texas</td>
            <td>9</td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>John Jacob</td>
            <td>John.Jacob@gmail.com</td>
            <td>9433723140</td>
            <td>1991-05-03</td>
            <td>Plano, Texas</td>
            <td>9</td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>John Jacob</td>
            <td>John.Jacob@gmail.com</td>
            <td>9433723140</td>
            <td>1991-05-03</td>
            <td>Plano, Texas</td>
            <td>9</td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>John Jacob</td>
            <td>John.Jacob@gmail.com</td>
            <td>9433723140</td>
            <td>1991-05-03</td>
            <td>Plano, Texas</td>
            <td>9</td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>John Jacob</td>
            <td>John.Jacob@gmail.com</td>
            <td>9433723140</td>
            <td>1991-05-03</td>
            <td>Plano, Texas</td>
            <td>9</td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </td>
            <td>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </td>
          </tr>
        </table>
      </div>
      <div class="pt-6"></div>
      <div class="pt-6"></div>
    </>
  )
}
export default Approve_Users;
