import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Quicklinks from "./Quicklinks";
function Dei_Events() {
  return (
      <div>
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-12 col-lg-12">
              <div className="p-1">
                <div className="academia-jobs">
                  <div className="">
                    <h2>Events Posted</h2>
                    <Link
                      to="/dei_create_events"
                      className="href job-add p-1 text-no-decoration"
                    >
                      <i className="fa fa-solid fa-plus"></i>
                      Create Events
                    </Link>
                  </div>
                  <div className="job-card pt-4">
                    <h3>Events</h3>
                    <p>Asian Eaters</p>
                    <p>American Nights</p>
                    <div className="text-right">
                      <ul className="academic-job-list">
                        <li>
                          <Link
                            to="/"
                            className="href job-view p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-eye"></i>
                            View
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/"
                            className="href job-update p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-plus"></i>
                            Update
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/"
                            className="href job-delete p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-trash"></i>
                            Delete
                          </Link>
                        </li>
                      </ul>
                      <br />
                    </div>
                    <hr className="text-color-1" />
                  </div>
                </div>
              </div>
            </div>
            <Quicklinks />
          </div>
        </div>
      </div>
  );
}

export default Dei_Events;
