import { Link } from "react-router-dom";
import Quicklinks from "./Quicklinks";
function Dei_Create_Events() {
  return (
    <>
      <div>
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-6 col-sm-12 col-md-8 col-lg-8">
              <div className="p-1">
                <div className="academia-jobs">
                  <h2>Create Event</h2>
                  <form action="/" method="post">
                    <input
                      type="text"
                      className="ip"
                      placeholder="Enter Event Name"
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      className="ip"
                      placeholder="Enter Event Description"
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      className="ip"
                      placeholder="Enter Event Location"
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      className="ip"
                      placeholder="Enter Event Date"
                    />
                    <br />
                    <br />
                    <br />
                    <input
                      type="submit"
                      className="ip-button"
                      value="Publish"
                    />
                  </form>
                </div>
              </div>
            </div>
            <Quicklinks />
          </div>
        </div>
      </div>
      </>
  );
}

export default Dei_Create_Events;
