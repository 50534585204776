import { Link } from "react-router-dom";
import Quicklinks from "./Quicklinks";
import { useState } from "react";
import axios from "axios";
import JobView from "./JobView";
function Dei_Applications() {

  const[jobPostings,setJobPostings] = useState([]);
  axios.post('https://mdd8423.uta.cloud/php/dei.php',{
  // axios.post('http://localhost/urm/dei.php',{
    action:"list"
  })
  .then((response)=>{
    setJobPostings(response.data.data);
  })
  return (
    <>
      <div>
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-12 col-lg-12">
              <div className="p-1">
                <div className="academia-jobs">
                  <div>
                    <h2>Posted Jobs</h2>
                    <input
                      type="text"
                      placeholder="Search"
                      className="display-inline ip"
                      name="searchbar"
                    />
                    <p className="text-right">
                      <select id="" className="ip" name="search_filters">
                        <option value="name">Name</option>
                        <option value="location">Location</option>
                        <option value="education">Education</option>
                        <option value="research">Research</option>
                      </select>
                    </p>
                    <button className="ip-button">Search</button>
                  </div>
                  {jobPostings.map((job)=>(
                        <JobView
                        jid = {job.JobID}
                        desc = {job.description}
                        name={job.name}
                        location={job.location}
                        type={job.type}
                        pay={job.pay}
                        />
                    ))}
                </div>
              </div>
            </div>
            <Quicklinks />
          </div>
        </div>
      </div>
      </>
  );
}

export default Dei_Applications;
