import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import UserList from "./UserList";
function View_Users(){

    const [users, setUsers] = useState([]);
    axios.get('https://mdd8423.uta.cloud/php/admin.php?action=list')
    // axios.get('http://localhost/urm/admin.php?action=list')
    .then((response) => {
        // console.log(response);
        setUsers(response.data.data);
    })
    return(
        <>
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-8 col-sm-12 col-md-8 col-lg-8">
            <div class="p-1">
              <div class="academia-jobs">
                <div class="">
                  <h2>User List</h2>
                  <Link
                    to="/Create_User_Entry"
                    class="job-add p-1 text-no-decoration">
                    <i class="fa fa-solid fa-plus"></i>
                    Create New User
                  </Link>
                </div>
                <div id="jlist">
                {users.map((u)=>(
                        <UserList
                        upid = {u.PersonID}
                        uroleid = {u.RoleID}
                        uname = {u.Name}
                        uline1 = {u.Line1}
                        uline2 = {u.Line2}
                        uzipcode = {u.Zipcode}
                        uemail = {u.Email}
                        upassword = {u.Password}
                        />
                    ))}
                </div>
              </div>
            </div>
          </div>
          {/* <Quicklinks /> */}
        </div>
      </div>
      </>
    )
}

export default View_Users;