// import "./admin.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { useState } from "react";
function Create_User_Entry() {
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   try {
  //     axios.post("https://localhost/urm/admin.php", {
  //       action:"create",
  //       urole:urole,
  //       uname:uname,
  //       uline1:uline1,
  //       uline2:uline2,
  //       uzipcode:uzipcode,
  //       uemail:uemail,
  //       upassword:upassword
  //     });
  //   } catch (error) {
  //     console.log(response.data.message);
  //   }
  // }


  // Variables
  const [uname, setUname] = useState("");
  const [uline1, setUline1] = useState("");
  const [uline2, setUline2] = useState("");
  const [uzipcode, setUzipcode] = useState("");
  const [uemail, setUemail] = useState("");
  const [upassword, setUpassword] = useState("");
  const [urole, setUrole] = useState("");

  // On Change

  const handleunamechange = (event) => {
    setUname(event.target.value);
  };
  const handleuline1change = (event) => {
    setUline1(event.target.value);
  };
  const handleuline2change = (event) => {
    setUline2(event.target.value);
  };
  const handleuzipcodechange = (event) => {
    setUzipcode(event.target.value);
  };
  const handleuemailchange = (event) => {
    setUemail(event.target.value);
  };
  const handleupasswordchange = (event) => {
    setUpassword(event.target.value);
  };
  const handleurolechange = (event) => {
    setUrole(event.target.value);
  };


  // Send Data on Submit
  const handleSubmit = (event) =>{
    event.preventDefault();
  axios
  .post("https://mdd8423.uta.cloud/php/admin.php",{
    // .post("http://localhost/urm/admin.php", {
      urole:urole,
      uname:uname,
      uline1:uline1,
      uline2:uline2,
      uzipcode:uzipcode,
      uemail:uemail,
      upassword:upassword,
      action:"create"
    })
    .then((response) => {
      console.log(response);
      // if (response.data.status === "Success") {
      //   // window.location.href = "/Academia_Jobs";
      // } else {
      //   // setError(response.data.message);
      // }
    })
  };


  return (
      <div class="container pt-6">
        <div class="academia-dashboard col-6 m-auto p-2">
          <div class="m-auto"><h2>Create User</h2></div>
          <form onSubmit={handleSubmit}>
              <label for="name">Name:</label>
              <input type="text" class="ip" name="name" value={uname} onChange={handleunamechange} required />
              <br/><br/>
              <label for="Line1">Line1:</label>
              <input type="text" class="ip" name="Line1" value={uline1} onChange={handleuline1change} required />
              <br/><br/>
              <label for="Line2">Line2:</label>
              <input type="text" class="ip" name="Line2" value={uline2} onChange={handleuline2change} required />
              <br/><br/>
              <label for="zipcode">Zipcode:</label>
              <input type="text" class="ip"name="zipcode" value={uzipcode} onChange={handleuzipcodechange} required />
              <br/><br/>
              <label for="email">Email:</label>
              <input type="email" class="ip" name="email" value={uemail} onChange={handleuemailchange} required />
              <br/><br/>
              <label for="pass">Password:</label>
              <input type="password" class="ip" name="pass" value={upassword} onChange={handleupasswordchange} required />
              <br/><br/>
              <select name="role" class="ip" value={urole} onChange={handleurolechange}>
                <option value="1">Admin</option>
                <option value="2">Academia</option>
                <option value="3">Dei Officer</option>
                <option value="4">Candidate</option>
              </select>
              <br/><br/><br/>
              <input class="ip-button" type="submit" value="Create User"/>
              
          </form>
        </div>
      </div>
  );
}
export default Create_User_Entry;
